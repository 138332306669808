<!-- TODO: Refact in two components: RTDB TASK/QUEUE, EMAIL_MIGRATION -->
<template>
  <FullscreenStepsDialog
    :show="true"
    :title="$t('emailMigration.title')"
    :steps="steps"
    :current-step="step"
    :linear="true"
    :beta="true"
    @update:current-step="step = $event"
    @close="() => this.$router.push({ name: 'Users' })"
  >
    <!-- Introdução -->
    <template #stepper-content.1>
      <FullscreenStepsIntro
        :image-src="emailMigrationImage"
        :title="$t('emailMigration.intro.title')"
        :description="$t('emailMigration.intro.description')"
        :benefits="[
          $t('emailMigration.intro.benefits.1'),
          $t('emailMigration.intro.benefits.2'),
          $t('emailMigration.intro.benefits.3'),
        ]"
        @start="() => (step = nextStep[step])"
      />
    </template>
    <!-- Lista de Transferencias de email da empresa -->
    <template #stepper-content.2>
      <!-- SCOPE ALERT  -->
      <Alert v-if="needAcceptScopes" color="warning" outlined>
        <v-row class="ma-0 pa-0" no-gutters align="center">
          <v-col cols="8">
            <h3 class="text-h6">
              <strong>{{ $t("scopesAlert.alertTitle") }}</strong>
            </h3>
            <span>
              {{ $t("scopesAlert.emailMigration.alertMessage") }}
            </span>
            <br />
            <span v-if="isAdmin">
              {{ $t("scopesAlert.emailMigration.resolveScopes") }}
            </span>
            <span v-else>
              {{ $t("scopesAlert.alertMessage3") }}
            </span>
          </v-col>
          <v-divider
            vertical
            class="my-4 info"
            style="opacity: 0.22"
          ></v-divider>
          <v-col cols="4" class="pa-0 ma-0 d-flex justify-space-around">
            <div>
              <v-btn
                text
                small
                color="secondary lighten-2"
                href="https://conectasuite.com/perguntas-frequentes/como-permitir-acesso-a-novos-escopos-no-painel-do-google-administrativo-2/"
                target="_blank"
                class="mr-2 text-body-2 font-weight-medium"
              >
                {{ $t("action.knowMore") }}
              </v-btn>
              <v-btn
                v-if="isAdmin"
                text
                small
                outlined
                color="warning"
                :href="missingScopesText"
                target="_blank"
                class="ml-auto text-body-2 font-weight-medium"
              >
                {{ $t("action.acceptScopes") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </Alert>
      <div class="d-flex align-center">
        <h1 class="font-weight-bold mb-3" style="font-size: 22px">
          {{ $t("emailMigration.groupEmailTransfer") }}
        </h1>
      </div>
      <v-card-title class="d-flex flex-column align-start mb-5">
        <div class="text-subtitle-2 grey--text text--darken-1">
          {{ $t("driveMigration.processes.resume") }}
        </div>
        <div class="text-subtitle-1 grey--text text--darken-1">
          {{ $t("driveMigration.processes.start") }}
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="mx-0 px-0 py-0 mb-5">
        <v-data-table
          :items="Object.values(companyEmailTransfers)"
          :headers="tableHeaders"
          :items-per-page="50"
          :loading="loading"
          :disabled="loading"
          :single-expand="false"
          :expanded.sync="expanded"
          fixed-header
          show-expand
          item-key="createdAt"
        >
          <!-- <template #[`item.actions`]="{ item }">
            <v-btn
              text
              :outlined="!loading && item.status === 'toDo'"
              color="accent"
              :dark="!loading && item.status === 'toDo'"
              :disabled="loading || item.status !== 'toDo'"
              class="px-1"
              @click="
                startEmailProcess(item);
                step = 5;
              "
            >
              <v-icon
                v-if="item.status === 'toDo'"
                class="me-1"
                size="24"
                right
                dark
              >
                ph-fill ph-play
              </v-icon>
              {{
                item.status !== "toDo"
                  ? $t("common.concluded")
                  : $t("common.resume")
              }}
            </v-btn>
          </template> -->

          <template #[`item.createdAt`]="{ item }">
            <div>
              {{ $t("common.theStart") }}:
              {{ formatTimestamp(item.createdAt) }}
            </div>
          </template>

          <template #[`item.status`]="{ item }">
            <div class="d-flex justify-space-between">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="d-flex">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      :color="status[item.status].color"
                      v-text="status[item.status].icon"
                      class="my-2 me-3"
                    />
                    <span class="font-weight-medium my-2">
                      {{ $t(status[item.status].textKey) }}
                    </span>
                  </div>
                </template>
                <span class="font-weight-medium">
                  {{ $t(status[item.status].textKey) }}
                </span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.status === 'failed'">
                <template #activator="{ on, attrs }">
                  <div class="d-flex justify-start">
                    <v-icon
                      color="grey"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      v-text="'ph-question'"
                    />
                  </div>
                </template>
                <span class="font-weight-medium">
                  {{ $t("driveMigration.processes.callSupport") }}
                </span>
              </v-tooltip>
            </div>
          </template>

          <template #[`item.user_group`]="{ item }">
            <div>{{ $t("common.origin") }}: {{ item.user?.email }}</div>
            <div>{{ $t("common.destination") }}: {{ item.group }}</div>
          </template>

          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pl-4 pt-4 pb-4 grey lighten-4">
              <div class="d-flex flex-column">
                <div class="d-flex">
                  <div class="font-weight-medium mb-3 me-2">
                    {{ $t("common.author") }}:
                  </div>
                  <div class="mb-3">
                    <Author :author="findAuthor(item.author.email) || {}" />
                  </div>
                </div>
                <v-divider />

                <div>
                  <span class="d-block">
                    {{
                      `${$t("emailMigration.emailsMigrated")}: ${
                        item["migrated"] + item["failed"]
                      } ${$t("common.of")} ${item.totalEmails}`
                    }}
                  </span>
                  <span class="d-block"
                    >{{
                      `${$t("emailMigration.successfullyMigrated")}: ${
                        item["migrated"]
                      }`
                    }}
                  </span>
                  <span class="d-block"
                    >{{
                      `${$t("emailMigration.migrationFailure")}: ${
                        item["failed"]
                      }`
                    }}
                  </span>
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </template>

    <!-- Iniciar uma transferência -->
    <template #stepper-content.3>
      <v-form @submit.prevent="">
        <div class="d-flex align-center">
          <h1 class="font-weight-bold mb-3" style="font-size: 22px">
            {{ $t("emailMigration.start.title") }}
          </h1>
        </div>
        <span class="text-subtitle-2 grey--text text--darken-1">
          {{ $t("emailMigration.start.userTip") }}
        </span>
        <v-autocomplete
          v-model="user"
          :items="usersWithActiveMailBox"
          item-text="email"
          item-value="id_google"
          return-object
          :label="$t('common.selectUser')"
          clearable
          open-on-clear
          outlined
          class="mt-4"
          color="primary"
          :disabled="loading"
          :loading="loading"
        />
        <span class="text-subtitle-2 grey--text text--darken-1">
          {{ $t("emailMigration.start.groupTip") }}
        </span>
        <v-autocomplete
          v-model="group"
          :items="groups"
          item-text="email"
          item-value="id_google"
          return-object
          :label="
            user
              ? $t('emailMigration.start.selectGroup')
              : $t('emailMigration.start.tryUser')
          "
          clearable
          open-on-clear
          outlined
          color="primary"
          class="mt-4"
          :disabled="loading || !user"
          :loading="loading"
        />
        <span class="text-subtitle-2 grey--text text--darken-1">
          {{ $t("emailMigration.start.startDateTip") }}
        </span>
        <v-autocomplete
          v-model="startDate"
          :items="startDates"
          item-text="text"
          item-value="value"
          return-object
          :label="
            startDate
              ? $t('emailMigration.start.selectStartDate')
              : $t('emailMigration.start.theEntirePeriod')
          "
          clearable
          open-on-clear
          outlined
          color="primary"
          class="mt-4"
          :disabled="loading"
          :loading="loading"
        />
        <div v-if="startDate?.value === 'customPeriod'">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                style="width: 100%"
                append-icon="ph-calendar-blank"
                :value="startDateText"
                :label="$t('emailMigration.start.selectStartDate')"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="startDateSelected"
              :max="addDaysFormat(0)"
              @input="menuStartDate = false"
            >
              <v-spacer />
            </v-date-picker>
          </v-menu>
        </div>
        <span class="text-subtitle-2 grey--text text--darken-1">
          {{ $t("emailMigration.start.includeSpamAndTrashTip") }}
        </span>
        <v-checkbox
          style="width: 100%"
          v-model="includeSpamAndTrash"
          :label="$t('emailMigration.start.includeSpamAndTrash')"
          class="mt-4 mb-0 py-0"
        ></v-checkbox>
      </v-form>
    </template>

    <!-- Preparar -->
    <template #stepper-content.4>
      <v-card-text>
        <div class="d-flex align-center">
          <h1 class="font-weight-bold mb-3" style="font-size: 22px">
            {{
              loading
                ? $t("emailMigration.prepare.loading")
                : $t("emailMigration.prepare.ready")
            }}
          </h1>
        </div>
        <div class="d-flex justify-start mt-5 mb-3 font-weight-medium">
          <v-simple-table style="max-width: 500px" :loading="loading">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="text-capitalize">{{ $t("common.user") }}:</td>
                  <td>{{ user?.email || "" }}</td>
                </tr>
                <tr>
                  <td>{{ $t("common.group") }}:</td>
                  <td>{{ group || "" }}</td>
                </tr>
                <tr>
                  <td>{{ $t("emailMigration.prepare.files") }}:</td>
                  <td>
                    {{
                      `${loadingEmailsFromGoogle ? "carregando " : ""}  ${
                        emailMessagesIds.length
                      }`
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <div
          v-if="!loading"
          class="mt-5 text-subtitle-1 grey--text text--darken-1 font-weight-medium"
        >
          {{ $t("emailMigration.prepare.continue") }}
        </div>
      </v-card-text>
    </template>
    <!-- Execução -->
    <template #stepper-content.5>
      <!-- Erro -->
      <!-- Em andamento / Concluído -->
      <v-card-text :loading="loading">
        <div class="d-flex align-center">
          <h1 class="font-weight-bold mb-1" style="font-size: 22px">
            {{
              $t("emailMigration.executing.title", {
                email: user.email,
                group: group,
              })
            }}
          </h1>
        </div>
        <div v-if="emailTransferInProgress">
          <v-progress-linear
            color="primary"
            class="my-5 white--text"
            :value="progress"
            height="25"
            rounded
          >
            <strong>
              {{
                `${$t("emailMigration.emailsMigrated")}: ${
                  emailTransferInProgress["migrated"] +
                  emailTransferInProgress["failed"]
                } ${$t("common.of")} ${emailTransferInProgress.totalEmails}`
              }}
            </strong>
          </v-progress-linear>
          <span class="d-block">
            {{
              `${$t("emailMigration.successfullyMigrated")}: ${
                emailTransferInProgress["migrated"]
              }`
            }}
          </span>
          <span class="d-block"
            >{{
              `${$t("emailMigration.migrationFailure")}: ${
                emailTransferInProgress["failed"]
              }`
            }}
          </span>

          <span class="d-block mt-2">
            status:
            <v-chip
              class="white--text font-weight-bold"
              small
              :color="
                emailTransferInProgress['status'] == 'FINISHED'
                  ? 'green'
                  : 'blue'
              "
            >
              {{
                $t(
                  emailTransferInProgress["status"] == "toDo"
                    ? "common.running"
                    : status[emailTransferInProgress["status"]].textKey
                )
              }}
            </v-chip>
          </span>
        </div>
      </v-card-text>
    </template>

    <!-- Ações e botões -->
    <template #stepper-content.actions>
      <v-btn
        height="52"
        large
        text
        class="text-none text-body-1 font-weight-bold px-8"
        :disabled="!stepBackAction[step]"
        @click="step = backStep[step]"
      >
        {{ stepBackAction[step] ? $t(stepBackAction[step]) : "" }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="step === 5"
        height="52"
        large
        outlined
        color="accent"
        class="text-none text-body-1 font-weight-bold px-8 me-3"
        @click="leaveProcessRoom"
      >
        <!-- <v-icon class="me-1" size="24" right dark>ph-fill ph-pause-circle </v-icon> -->
        <!-- {{ $t("common.pause") }} -->
        {{ $t("action.finish") }}
      </v-btn>
      <v-btn
        height="52"
        large
        color="accent"
        class="text-none text-body-1 font-weight-bold px-8"
        :disabled="
          step !== 5 &&
          ((step === 3 && (!user?.key || !group)) ||
            (step === 4 && (!user?.key || !group)) ||
            (step === 2 && needAcceptScopes) ||
            !stepNextAction[step] ||
            loading)
        "
        @click="step !== 5 ? (step = nextStep[step]) : exit()"
        :loading="step !== 5 && loading"
      >
        {{ stepNextAction[step] ? $t(stepNextAction[step]) : "" }}
        <v-icon size="24" right dark>{{
          step !== 5 ? "ph-arrow-right" : "ph-minus"
        }}</v-icon>
      </v-btn>
    </template>
  </FullscreenStepsDialog>
</template>

<script>
import * as moment from "moment";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { INCREMENTAL_SCOPES } from "@/helpers/variables/scopes";
import { DISCOVERY_DOCS } from "@/helpers/variables/discoveryDocs";
import FullscreenStepsDialog from "@/components/main/shared/FullscreenStepsDialog.vue";
import FullscreenStepsIntro from "@/components/main/shared/FullscreenStepsIntro.vue";
import Author from "@/components/logs/cells/Author.vue";

import {
  addDaysFormat,
  formatDate,
  todayDataBaseFormat,
} from "@/helpers/services/utils";

export default {
  name: "EmailMigration",

  components: {
    FullscreenStepsDialog,
    FullscreenStepsIntro,
    Author,
  },

  data: () => ({
    emailMigrationImage: require("@/assets/images/migrate-emails.svg"),
    expanded: [],
    status: {
      unknown: {
        textKey: "common.unknown",
        icon: "ph-fill ph-shield-warning",
        color: "warning",
      },
      toDo: {
        textKey: "common.unfinished",
        icon: "ph-fill ph-clock",
        color: "accent",
      },
      FINISHED: {
        textKey: "common.finished",
        icon: "ph-fill ph-shield-check",
        color: "success",
      },
      doneWithFailures: {
        textKey: "common.concludedWithFailures",
        icon: "ph-fill ph-shield-check",
        color: "warning",
      },
      failed: {
        textKey: "common.failed",
        icon: "ph-fill ph-shield-slash",
        color: "error",
      },
    },

    steps: [
      {
        titleKey: "emailMigration.steps.intro.title",
        subtitleKey: "emailMigration.steps.intro.subtitle",
        id: 1,
      },
      {
        titleKey: "emailMigration.steps.processes.title",
        subtitleKey: "emailMigration.steps.processes.subtitle",
        id: 2,
      },
      {
        titleKey: "emailMigration.steps.start.title",
        subtitleKey: "emailMigration.steps.start.subtitle",
        id: 3,
      },
      {
        titleKey: "emailMigration.steps.prepare.title",
        subtitleKey: "emailMigration.steps.prepare.subtitle",
        id: 4,
      },
      {
        titleKey: "emailMigration.steps.executing.title",
        subtitleKey: "emailMigration.steps.executing.subtitle",
        id: 5,
      },
    ],
    stepBackAction: {
      1: "",
      2: "common.back",
      3: "common.back",
      4: "common.back",
      5: "",
    },
    stepNextAction: {
      1: "",
      2: "driveMigration.startProcess",
      3: "common.prepare",
      4: "common.advance",
      5: "action.minimize",
    },
    backStep: {
      1: 1,
      2: 1,
      3: 2,
      4: 2,
      5: 2,
    },
    nextStep: {
      1: 2,
      2: 3,
      3: 4,
      4: 5,
      5: 2,
    },

    loadingUserGroups: false,
    loadingProcessCreation: false,

    groupScope: INCREMENTAL_SCOPES.ADMIN_DIRECTORY_GROUP,
    gmailScope: INCREMENTAL_SCOPES.GMAIL_READONLY,
    migrateScope: INCREMENTAL_SCOPES.ADMIN_GROUP_MIGRATION,
    needAcceptScopes: false,
    groups: [],
    startDate: null,
    menuStartDate: false,
    startDateSelected: todayDataBaseFormat,
    includeSpamAndTrash: false,
  }),

  computed: {
    ...mapGetters([
      "users",
      "scopeCode",
      "groupEmails",
      "isAdmin",
      "companyEmailTransfers",
      "emailStep",
      "userToMigrateEmail",
      "groupToMigrate",
      "tokenToReadEmails",
      "emailMessagesIds",
      "loadingClient",
      "loadingEmailMigration",
      "loadingCompanyToken",
      "emailTransferInProgress",
      "loadingEmailsFromGoogle",
    ]),

    step: {
      get() {
        return this.emailStep;
      },
      set(value) {
        this.setEmailStep(value);
      },
    },

    group: {
      get() {
        return this.groupToMigrate;
      },
      set(value) {
        this.setMigrateEmailGroup(value);
      },
    },

    user: {
      get() {
        return this.userToMigrateEmail;
      },
      set(value) {
        this.setMigrateEmailUser(value);
      },
    },

    missingScopesText() {
      return `https://admin.google.com/ac/apps/gmail/marketplace/appdetails/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
    },

    progress() {
      if (!this.emailTransferInProgress) return;
      const { totalEmails, failed, migrated } = this.emailTransferInProgress;
      return (failed + migrated / totalEmails) * 100;
    },

    loading() {
      return (
        !this.users?.length ||
        this.loadingClient ||
        this.loadingCompanyToken ||
        this.loadingUserGroups ||
        this.loadingProcessCreation ||
        this.loadingEmailMigration
      );
    },

    usersWithActiveMailBox() {
      return this.users.filter((item) => item.is_gmail_enabled);
    },

    groupScopeCode() {
      return this.scopeCode(this.groupScope);
    },
    tableHeaders() {
      return [
        {
          value: "createdAt",
          text: this.$t("common.date"),
          sortable: true,
        },
        {
          value: "status",
          text: this.$t("common.status"),
          sortable: true,
          width: 190,
        },
        {
          value: "user_group",
          text: `${this.$t("common.origin")}/${this.$t("common.destination")}`,
          sortable: false,
        },
        {
          value: "actions",
          text: "",
          sortable: false,
          width: 110,
          align: "center",
        },
      ];
    },

    startDates() {
      return [
        {
          text: this.$t("emailMigration.start.pastYear"),
          value: moment().subtract(1, "years"),
        },
        {
          text: this.$t("common.past6Months"),
          value: moment().subtract(6, "months"),
        },
        {
          text: this.$t("common.past3Months"),
          value: moment().subtract(3, "months"),
        },
        {
          text: this.$t("emailMigration.start.past1Month"),
          value: moment().subtract(1, "months"),
        },
        {
          text: this.$t("emailMigration.start.customPeriod"),
          value: "customPeriod",
        },
      ];
    },

    startDateText() {
      if (!this.startDateSelected) {
        return null;
      }
      return formatDate(this.startDateSelected);
    },
  },
  watch: {
    async step(step, oldStep) {
      if (step == oldStep) return;

      if (step === 4) {
        this.loadingProcessCreation = true;
        await this.getUserTokenToReadEmails();

        if (!this.tokenToReadEmails) {
          this.step = 2;
          this.loadingProcessCreation = false;
          return;
        }

        const query = {
          includeSpamAndTrash: this.includeSpamAndTrash,
        };

        if (
          this.startDateSelected &&
          this.startDate?.value === "customPeriod"
        ) {
          query.startDate = moment(this.startDateSelected);
        } else if (
          this.startDate?.value &&
          this.startDate?.value !== "customPeriod"
        ) {
          query.startDate = this.startDate.value;
        }

        await this.fetchEmailsFromGoogle(query);

        if (!this.emailMessagesIds.length) {
          this.loadingProcessCreation = false;
          this.step = 3;
          return;
        }

        await this.uploadEmailBatches();
        this.loadingProcessCreation = false;
      } else if (step === 5) {
        this.startMailTransferToGroup();
      } else if (step === 3) {
        this.user = {};
        this.group = null;
        this.setTokenToReadUserEmails("");
        this.setEmailMessagesIds([]);
        if (!this.groupEmails.length || !this.groups.length)
          await this.loadGroups();
      }
    },
  },

  methods: {
    addDaysFormat,

    formatTimestamp(timestamp) {
      return new Date(timestamp).toLocaleDateString(
        localStorage?.getItem?.("lang") ||
          window?.navigator?.language ||
          "pt-BR",
        {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }
      );
    },

    findAuthor(email) {
      return this.users?.find((user) => user.email == email) || {};
    },
    ...mapMutations([
      "setEmailMessagesIds",
      "setTokenToReadUserEmails",
      "setFinishEmailTransfer",
      "setEmailStep",
      "setMigrateEmailGroup",
      "setMigrateEmailUser",
      "startEmailProcess",
      "setShowTasksProgress",
    ]),

    ...mapActions([
      "checkScopes",
      "getGoogleWorkspaceGroups",
      "getCompanyEmailTransfers",
      "getUserTokenToReadEmails",
      "fetchEmailsFromGoogle",
      "loadGAPIScript",
      "uploadEmailBatches",
      "startMailTransferToGroup",
    ]),

    async loadGroups() {
      this.loadingUserGroups = true;
      let groupScopeCode = "";
      if (!this.groupScopeCode) {
        const response = await this.checkScopes({
          scopes: [this.groupScope],
        });
        groupScopeCode = response.data.code;
      }
      if ((this.groupScopeCode || groupScopeCode) !== "ACCEPTED_SCOPES") return;
      await this.getGoogleWorkspaceGroups();
      this.groups = this.groupEmails;
      this.loadingUserGroups = false;
    },
    async startProcess(process) {
      this.user = process.user;
      this.group = process.group;
      this.startMailTransferToGroup();
      this.step = 5;
    },

    leaveProcessRoom() {
      this.setFinishEmailTransfer();
      this.step = 2;
      this.user = null;
      this.group = null;
    },

    exit() {
      // this.setShowTasksProgress(true);
      this.$router.push({ name: "Users" });
    },
  },

  async beforeMount() {
    await this.checkScopes({
      scopes: [this.groupScope, this.migrateScope],
    }).catch(() => {
      this.needAcceptScopes = true;
      return;
    });

    await this.loadGAPIScript({
      scopes: [this.migrateScope, this.gmailScope],
      discoveryDocs: [DISCOVERY_DOCS.GMAIL, DISCOVERY_DOCS.GROUPS_MIGRATION],
    });
    await this.getCompanyEmailTransfers();
  },
};
</script>

<style>
.email-migration .v-select__selections {
  line-height: 24px;
}
</style>
